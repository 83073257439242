import type { RadioStylesType } from '@material-tailwind/react'

const radio: RadioStylesType = {
  styles: {
    base: {
      root: {
        display: 'inline-flex',
        alignItems: 'items-center',
        width: 'w-full',
        border: 'border',
        borderColor: 'border-black',
      },
      label: {
        width: 'w-full',
      },
    },
    colors: {},
  },
}

export default radio
