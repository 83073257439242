import type { DialogStylesType } from '@material-tailwind/react'

const dialog: DialogStylesType = {
  styles: {
    base: {
      backdrop: {
        overflowY: 'overflow-y-auto',
        height: 'h-full',
      },
      container: {
        maxHeight: '95vh',
      },
    },
  },
}

export default dialog
